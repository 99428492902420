import React from 'react'
import { PRICINGS } from '../../../Utilities/APIs'
import DINCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINCRUD/DINCRUD'

function Pricing() {
    return (
        <DINCRUD
            // common props
            api={PRICINGS}
            screenTopicSingular='Pricing'
            screenTopicPlural='Pricings'

            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            // allowDelete


        />
    )
}

export default Pricing
import React from 'react'

import axios from 'axios'
import { useSelector } from 'react-redux';
import { EMPLOYEES_API } from '../../../../Utilities/APIs'
import './CreateAdmin.css'
function CreateAdmin({
  setShowModal, setShowCreateAdmin, targetID, triggerFetch
}) {

    const { employee } = useSelector(state => state.auth)

    console.log(targetID);

  async function handleCreateAdmin(e) {
      e.preventDefault()

      const config = {
        headers: {
            Authorization: `Bearer ${employee.token}`
        }
    }
    console.log(config);
    const response = await axios.patch(EMPLOYEES_API + 'createAdminBySuperAdmin/' + targetID, {}, config)

    if (response) {
      setShowModal(false);
      triggerFetch()
    }
  }
  return (
    <div className='modal_delete_content'>
      <p style={{textAlign: 'center'}}>
        Are you sure you want to make this employee an admin?

      </p>

      <div className="modal_group_buttons ">
        <button
          className="delete"
          onClick={handleCreateAdmin}
        >Make Admin</button>

        <button className="cancel" onClick={() => {
          setShowModal(false);
          setShowCreateAdmin(false);
          
        }}>Cancel</button>
      </div>

    </div>
  )
}

export default CreateAdmin
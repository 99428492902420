import { useState, useEffect } from "react"
import axios from "axios"
import { SERVICES } from "../../../../Utilities/APIs"

function ViewService({

    //common props
    targetID, employee

}) {

    const [serviceInfo, setServiceInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetServiceInfo() {

            const { data } = await axios.get(SERVICES + 'getSingleService/' + targetID)
            setServiceInfo(data);
        }
        fetchAndSetServiceInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {serviceInfo &&
                <>
                    <h1>Name</h1>
                    <p>{serviceInfo.name}</p>
                    <h1>Short Description</h1>
                    <p>{serviceInfo.shortDescription}</p>
                    <h1>Long Description</h1>
                    <p>{serviceInfo.longDescription}</p>
                    <h1>Lottie Link</h1>
                    <p>{serviceInfo.lottieLink}</p>
                    <h1>Precedence</h1>
                    <p>{serviceInfo.precedence}</p>
                    <h1>Is Data Type Available?</h1>
                    <p>{serviceInfo.isDataTypeAvailable}</p>
                    <h1>Is Other Service?</h1>
                    <p>{serviceInfo.isOtherService}</p>
                </>
            }
        </div>
    )
}

export default ViewService
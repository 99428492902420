import React from 'react'
import { SUBCRIPTIONS } from '../../../Utilities/APIs'
import ECRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/ECRUD/ECRUD'

function Subcriptions() {
    return (
        <ECRUD

            // common props
            api={SUBCRIPTIONS}
            screenTopicSingular='Subcription'
            screenTopicPlural='Subcriptions'


            // crud header props
            showTotalInHeading

        />
    )
}

export default Subcriptions
import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiContactsBook2Fill, RiHome4Line, RiPriceTag3Line, RiServiceLine, RiTeamLine } from 'react-icons/ri'

function WebsiteContentScreen() {
    return (
        <NavCardList numOfCards={'six'}>
            <NavCard cardName={'Homepage'} navCardLink='/homepage' ><RiHome4Line /></NavCard>
            <NavCard cardName={'Services'} navCardLink='/servicesScreen' ><RiServiceLine /></NavCard>
            <NavCard cardName={'Pricing'} navCardLink='/pricing' ><RiPriceTag3Line /></NavCard>
            <NavCard cardName={'About Us'} navCardLink='/teams' ><RiTeamLine /></NavCard>
            <NavCard cardName={'Contacts'} navCardLink={'/contacts'} ><RiContactsBook2Fill /></NavCard>


        </NavCardList>
    )
}

export default WebsiteContentScreen
import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';

import { RiComputerLine, RiHome2Line, RiNewspaperLine, RiUser2Line } from 'react-icons/ri';

import './SideBar.css';
import useLevels from '../../../../Utilities/Permissions/useLevels';
import RequestSideBarItem from './RequestSideBarItem/RequestSideBarItem';
import { useContext } from 'react';
import AppContext from '../../../../Context/AppContext';

function SideBar() {

    const { superAdmin, admin } = useLevels()
    const { pendingRequests } = useContext(AppContext)

    return (
        <section className="small_sidebar">
            <div className='container'>
                {(admin || superAdmin) &&
                <>
                    <SmallSideBarItem
                        link='/'
                        dataTip='Dashboard'
                        dataFor='Dashboard'
                    >
                        <RiHome2Line />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/websiteContentScreen'
                        dataTip='Website Content'
                        dataFor='Website Content'
                    >
                        <RiComputerLine />
                    </SmallSideBarItem>

                    

                    <RequestSideBarItem
                        link={pendingRequests > 0 ? '/pendingRequests' : '/requestScreen'}
                        dataTip='Requests'
                        dataFor='Requests'
                    />

                    <SmallSideBarItem
                        link='/usersScreen'
                        dataTip='Users'
                        dataFor='Users'
                    >
                        <RiUser2Line />
                    </SmallSideBarItem>
                </>
                }
                <SmallSideBarItem
                    link='/writeUps'
                    dataTip='Write-ups'
                    dataFor='Write-ups'
                >
                    <RiNewspaperLine />
                </SmallSideBarItem>


            </div>
        </section>
    )
}

export default SideBar

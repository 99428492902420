import { useEffect, useState } from "react"
import axios from "axios"

import { SERVICES } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import LinkInput from "../../../Partials/Layouts/Forms/FormInputs/LinkInput/LinkInput"
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import Switch from "react-switch";

function UpdateService({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [shortDescription, setShortDescription] = useState('')
    const [longDescription, setLongDescription] = useState('')
    const [lottieLink, setLottieLink] = useState('')
    const [precedence, setPredence] = useState('')
    const [isDataTypeAvailable, setIsDataTypeAvailable] = useState(false)
    const [isOtherService, setIsOtherService] = useState(false)

    function handleChange(nextChecked) {
        setIsDataTypeAvailable(nextChecked);
    };

    function handleOtherService(nextChecked) {
        setIsOtherService(nextChecked);
    };

    useEffect(() => {

        async function fetchAndSetServiceInfo() {

            const { data } = await axios.get(SERVICES + 'getSingleService/' + targetID)
            setName(data.name);
            setShortDescription(data.shortDescription);
            setLongDescription(data.longDescription);
            setLottieLink(data.lottieLink);
            setPredence(data.precedence);
            setIsDataTypeAvailable(data.isDataTypeAvailable);
            setIsOtherService(data.isOtherService);
        }
        fetchAndSetServiceInfo()
    }, [targetID])

    async function handleSubmit(e) {
        e.preventDefault()

        const service = { name, shortDescription, longDescription, lottieLink, precedence, isDataTypeAvailable, isOtherService }

        const response = await axios.patch(SERVICES + targetID, service)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />

            <ShortTextInput
                label={`Description`}
                value={shortDescription}
                placeholder={`Enter Description`}
                setState={setShortDescription}
            />
            <ShortTextInput
                label={`Long Description`}
                value={longDescription}
                placeholder={`Enter Long Description`}
                setState={setLongDescription}
            />
            <LinkInput
                label={`Lottie Link`}
                value={lottieLink}
                placeholder={`Enter Lottie Link`}
                setState={setLottieLink}
            />
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPredence}
            />
            <div>
                <label className="input_field_label">
                    Is Data Type Available?</label>
                <Switch
                    onChange={handleChange}
                    checked={isDataTypeAvailable}
                    className="react-switch"
                />
            </div>
            <div>
                <label className="input_field_label">
                    Is Other Service?</label>
                <Switch
                    onChange={handleOtherService}
                    checked={isOtherService}
                    className="react-switch"
                />
            </div>


            <FormSubmitButton text='Update Service' />
        </Form>
    )
}

export default UpdateService
import { useState } from "react"
import axios from "axios"

import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"

import { OPERATIONS } from "../../../../Utilities/APIs"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import Form from "../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"


function CreateOperation({

    service, employee, setShowCreateForm, setShowModal, triggerFetch
  

}) {
 
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [icon, setIcon] = useState('')
    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')
 

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('service', service._id)
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('icon', icon)
        itemData.append('image', image)
        itemData.append('precedence', precedence)

        const response = await axios.post(OPERATIONS , itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
           
            <h1 className="input_field_label">Service</h1>
            <p >{service.name}</p>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
             <ImageInput
                fieldId='1'
                state={icon}
                setState={setIcon}
                allowCreateImage
            >
                Upload Card Icon
            </ImageInput>
             <ImageInput
                fieldId='2'
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Card Image
            </ImageInput>
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />

            <FormSubmitButton text='Create Operation' />
        </Form>
    )
}

export default CreateOperation
import React from 'react'
import { TESTIMONIALS } from '../../../Utilities/APIs'
import DILNPTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DILNPTCRUD/DILNPTCRUD'

function Testimonials() {
    return (
        <DILNPTCRUD
            // common props
            api={TESTIMONIALS}
            screenTopicSingular='Testimonial'
            screenTopicPlural='Testimonials'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            // allowDelete

            textDBField='designation'
            textName='Designation'
            longTextDBField='companyName'
            longTextName='Company Name'
            // field props
            maxPrecedence={10}
        />
    )
}

export default Testimonials
import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Redux/Features/Auth/authSlice'

import './App.css'

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import AppSettings from './Screens/AppSettings/AppSettings'
import Register from './Screens/AuthScreens/Register/Register'
import SettingsScreen from './Screens/Settings/SettingsScreen'
import OrderSettings from './Screens/Settings/OrderSettings'
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'
import CompanyPortfolioScreen from './Screens/CompanyPortfolioScreen/CompanyPortfolioScreen'
import SolutionUses from './Screens/CompanyPortfolioScreen/SolutionUses'
import IndustryUses from './Screens/CompanyPortfolioScreen/IndustryUses'
import ServicesScreen from './Screens/ServicesScreen/ServicesScreen'
import DataTypes from './Screens/ServicesScreen/DataTypes/DataTypes'
import BlogsScreen from './Screens/BlogsScreen/BlogsScreen'
import Subcriptions from './Screens/BlogsScreen/Subcriptions'
import Pricing from './Screens/Settings/Pricing'
import Tools from './Screens/Settings/Tools'
import ServiceDataTypes from './Screens/ServicesScreen/DataTypes/ServiceDataTypes'
import Services from './Screens/ServicesScreen/Services/Services'
import ContactScreen from './Screens/ContactScreen/ContactScreen'
import Testimonials from './Screens/Settings/Testimonials'
import OperationsScreen from './Screens/ServicesScreen/OperationsScreen/OperationsScreen'
import QoutesScreen from './Screens/QoutesScreen/QoutesScreen'
import WebsiteContentScreen from './Screens/WebsiteContentScreen/WebsiteContentScreen'
import Homepage from './Screens/Homepage/Homepage'
import RequestScreen from './Screens/RequestScreen/RequestScreen'
import Slider from './Screens/Homepage/Slider'
import Teams from './Screens/WebsiteContentScreen/Teams'
import Blogs from './Screens/Blogs/Blogs'
import CaseStudies from './Screens/CaseStudies/CaseStudies'
import UsersScreen from './Screens/UsersScreen/UsersScreen'
import Admins from './Screens/AuthScreens/Admins/Admins'
import QoutesRequestScreen from './Screens/QoutesScreen/QoutesRequestScreen/QoutesRequestScreen'
import PendingRequest from './Screens/QoutesScreen/QoutesRequestScreen/PendingRequest'
import AllAcceptedRequests from './Screens/QoutesScreen/QoutesRequestScreen/AllAcceptedRequests'
import useLevels from '../Utilities/Permissions/useLevels'


function App() {

  const {employee} = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const {superAdmin, admin} = useLevels()

  axios.interceptors.response.use(
    (res) => {
       return res;
    },
    (err) => {
      if (err.response.status === 401) {
        dispatch(logout())
      }
       return Promise.reject(err);
    }
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>

            <Routes>

              <Route path='/login' element={!employee ? <Login /> : <Navigate to={'/'} />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/passwordReset/:token" element={<RecoverPassThree />} />
              
              <Route path='/employeeScreens' element={employee ? <EmployeesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employeeInvites' element={employee ? <InviteEmployees /> : <Navigate to={'/login'} />} />
              <Route path='/employees' element={employee ? <Employees /> : <Navigate to={'/login'} />} />
              <Route path='/admins' element={employee ? <Admins /> : <Navigate to={'/login'} />} />
              <Route path='/usersScreen' element={employee ? <UsersScreen /> : <Navigate to={'/login'} />} />

              <Route path='/' element={
                  employee 
                  ? 
                  (
                    (superAdmin || admin)
                    ?
                    <Dashboard /> 
                    :
                    <Navigate to={'/writeUps'} />
                  )
                  : 
                  <Navigate to={'/login'} />
              } />

              <Route path='/websiteContentScreen' element={employee ? <WebsiteContentScreen /> : <Navigate to={'/login'} />} />
              <Route path='/homepage' element={employee ? <Homepage /> : <Navigate to={'/login'} />} />
              <Route path='/sliders' element={employee ? <Slider /> : <Navigate to={'/login'} />} />
              <Route path='/teams' element={employee ? <Teams /> : <Navigate to={'/login'} />} />

              <Route path='/writeUps' element={employee ? <BlogsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/blogs' element={employee ? <Blogs /> : <Navigate to={'/login'} />} />
              <Route path='/caseStudies' element={employee ? <CaseStudies /> : <Navigate to={'/login'} />} />

              <Route path='/servicesScreen' element={employee ? <ServicesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/services' element={employee ? <Services /> : <Navigate to={'/login'} />} />
              <Route path='/dataTypes' element={employee ? <DataTypes /> : <Navigate to={'/login'} />} />
              <Route path='/serviceDataTypes/:parentID' element={employee ? <ServiceDataTypes /> : <Navigate to={'/login'} />} />
              <Route path='/operations/:parentID' element={employee ? <OperationsScreen /> : <Navigate to={'/login'} />} />

            
              <Route path='/subcriptions' element={employee ? <Subcriptions /> : <Navigate to={'/login'} />} />
              <Route path='/requestScreen' element={employee ? <RequestScreen /> : <Navigate to={'/login'} />} />
              <Route path='/qoutesRequestScreen' element={employee ? <QoutesRequestScreen /> : <Navigate to={'/login'} />} />
              <Route path='/pendingRequests' element={employee ? <PendingRequest /> : <Navigate to={'/login'} />} />
              <Route path='/allAcceptedRequests' element={employee ? <AllAcceptedRequests /> : <Navigate to={'/login'} />} />

              <Route path='/companyPortfolioScreen' element={employee ? <CompanyPortfolioScreen /> : <Navigate to={'/login'} />} />
              <Route path='/solutionUses' element={employee ? <SolutionUses /> : <Navigate to={'/login'} />} />
              <Route path='/industryUses' element={employee ? <IndustryUses /> : <Navigate to={'/login'} />} />


              <Route path='/settingsScreen' element={employee ? <SettingsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/appSettings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />
              <Route path='/orderSettings' element={employee ? <OrderSettings /> : <Navigate to={'/login'} />} />
              <Route path='/pricing' element={employee ? <Pricing /> : <Navigate to={'/login'} />} />
              <Route path='/tools' element={employee ? <Tools /> : <Navigate to={'/login'} />} />
              <Route path='/contacts' element={employee ? <ContactScreen /> : <Navigate to={'/login'} />} />
              <Route path='/testimonials' element={employee ? <Testimonials /> : <Navigate to={'/login'} />} />
              <Route path='/qoutes' element={employee ? <QoutesScreen /> : <Navigate to={'/login'} />} />
           
            </Routes>
            
          </section>
        </main>
      </BrowserRouter>
    </section>
  )
}

export default App

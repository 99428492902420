import React, { useContext } from 'react'

import axios from 'axios'
import { QUOTES } from '../../../../Utilities/APIs'
import AppContext from '../../../../Context/AppContext'

function AcceptedRequest({
    setShowModal,  setShowPendingSection, targetID ,triggerFetch, employee
}) {
    const { triggerFetchRequest } = useContext(AppContext)

    async function handleAccept(e) {
        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        e.preventDefault()
       

        const response = await axios.patch(QUOTES + 'statusChangeToAccepted/' + targetID , {}, config)

        if (response) {
            setShowModal(false);
            triggerFetch()
            triggerFetchRequest()
        }
    }
    return (
        <div className='modal_delete_content'>
            <p style={{textAlign: 'center'}}>
                
                Are you sure you want to accept this request?

            </p>

            <div className="modal_group_buttons">
                <button
                    className="delete"
                  onClick={handleAccept}
                >Accept</button>

                <button className="cancel" onClick={() => {
                    setShowModal(false);
                    setShowPendingSection(false)
                }}>Cancel</button>
            </div>

        </div>
    )
}

export default AcceptedRequest
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"


import { QUOTES } from "../../../../Utilities/APIs"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"

import ViewQoute from "../ViewQoute"


function AllAcceptedRequests() {

    const [acceptedQoutes, setAcceptedQoutes] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)

    const [toggleFetch, setToggleFetch] = useState(false)

    const { employee } = useSelector(state => state.auth)


    useEffect(() => {
        async function fetchAndSetAcceptedQoutes() {
            const { data } = await axios.get(QUOTES + 'getQuotesForAdmin/accepted')
            setAcceptedQoutes(data)
        }

        fetchAndSetAcceptedQoutes()

    }, [toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }
    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }
    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Accepted Qoutes (${acceptedQoutes?.length} in total)`}</h1>
                    {/* <CreateButton
                        screenTopicSingular='Service Request'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    /> */}
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Phone' />
                        <CRUDth th='Email' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {acceptedQoutes?.map(acceptedQoute => (

                            <CRUDTableRow key={acceptedQoute._id}>
                                <ShortTextCell text={acceptedQoute?.name} />
                                <ShortTextCell text={acceptedQoute?.phone} />
                                <ShortTextCell text={acceptedQoute?.email} />
                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={acceptedQoute._id}
                                        setTargetID={setTargetID}
                                    />
                               

                                  

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Request`) ||
                        (showUpdateForm && `Update  Request`) ||
                        (showDeleteSection && `Delete  Request`)
                    }
                >
                    {/* {
                        showCreateForm &&
                        <CreateService
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
                    {/* {
                        showUpdateForm &&
                        <UpdateService
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={QUOTES}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Request`)
                    }
                >
                    <ViewQoute
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
       

        </>
    )
}

export default AllAcceptedRequests
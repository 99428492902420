import React from 'react'
import { TOOLS } from '../../../Utilities/APIs'
import ILNPCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/ILNPCRUD/ILNPCRUD'

function Tools() {
    return (
        <ILNPCRUD
            // common props
            api={TOOLS}
            screenTopicSingular='Tool'
            screenTopicPlural='Tools'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            // allowDelete


            // field props
            maxPrecedence={5}
        />
    )
}

export default Tools
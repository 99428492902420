import React from 'react'
import { RiArticleLine, RiProfileLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function BlogsScreen() {
    return (
        <NavCardList numOfCards={'six'}>
            <NavCard cardName={'Blogs'} navCardLink='/blogs' ><RiArticleLine /></NavCard>
            <NavCard cardName={'Case Study'} navCardLink='/caseStudies' ><RiProfileLine /></NavCard>
        </NavCardList>
    )
}

export default BlogsScreen
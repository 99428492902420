import { useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { QUOTES} from "../../../Utilities/APIs"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import EmailInput from "../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"


function CreateQoute({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [organization, setOrganization] = useState('')
    const [message, setMessage] = useState('')
 

    async function handleSubmit(e) {

        e.preventDefault()
       const qoute = { name, phone, email,  organization ,  message }

        const response = await axios.post(QUOTES, qoute)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <NumberInput
                label={`Phone`}
                value={phone}
                placeholder={`Enter Phone`}
                setState={setPhone}
            />
            <EmailInput
                label={`Email`}
                value={email}
                placeholder={`Enter Email`}
                setState={setEmail}
            />
            <ShortTextInput
                label={`Organization`}
                value={organization}
                placeholder={`Enter Organization`}
                setState={setOrganization}
            />
            <ShortTextInput
                label={`Message`}
                value={message}
                placeholder={`Enter Message`}
                setState={setMessage}
            />
           

            <FormSubmitButton text='Create Qoute' />
        </Form>
    )
}

export default CreateQoute
import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, OPERATIONS } from "../../../../Utilities/APIs"


function ViewOperation({

    //common props
    targetID, employee

}) {

    const [info, setInfos] = useState(null)

    useEffect(() => {

        async function fetchAndSetOperationInfo() {

            const { data } = await axios.get(OPERATIONS + 'getSingleOperation/' + targetID)
            setInfos(data);
        }
        fetchAndSetOperationInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {info &&
                <>
                    <h1>Name</h1>
                    <p>{info.name}</p>
                    <h1>Icon</h1>
                    <img src={IMAGE_URL + info.icon} alt="" />
                    <h1>Image</h1>
                    <img src={IMAGE_URL + info.image} alt="" />
                    <h1>Description</h1>
                    <p>{info.description}</p>
                    <h1>Precedence</h1>
                    <p>{info.precedence}</p>
                  

                </>
            }
        </div>
    )
}

export default ViewOperation
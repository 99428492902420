import { useState, useEffect } from "react"
import axios from "axios"
import {  CASE_STUDIES, IMAGE_URL } from "../../../Utilities/APIs"

function ViewCaseStudy({

    //common props
    targetID, employee

}) {

    const [caseStudy, setCaseStudy] = useState(null)

    useEffect(() => {

        async function fetchAndSetCaseStudy() {

            const { data } = await axios.get(CASE_STUDIES + 'getSingleCaseStudyById/' + targetID)
            setCaseStudy(data);
        }
        fetchAndSetCaseStudy()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {caseStudy &&
                <>
                    <h1>Name</h1>
                    <p>{caseStudy.name}</p>
                    <h1>Image</h1>
                    <img src={IMAGE_URL + caseStudy.image} alt="" />
                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{__html: caseStudy.description}}
                    />
                    <h1>Short Description</h1>
                    <p>{caseStudy.shortDescription}</p>
                    <h1>Precedence</h1>
                    <p>{caseStudy.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewCaseStudy
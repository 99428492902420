import React from 'react'
import { INDUSTRIES } from '../../../Utilities/APIs'
import DINPCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINPCRUD/DINPCRUD'

function IndustryUses() {
  return (
    <DINPCRUD
    // common props
    api={INDUSTRIES}
    screenTopicSingular='Industry'
    screenTopicPlural='Industries'


    // crud header props
    showTotalInHeading


    // crud props
    allowCreate
    allowUpdate
    // allowDelete


    // field props
    maxPrecedence={5}

/>
  )
}

export default IndustryUses
import React from 'react'
import { SLIDERS_API } from '../../../Utilities/APIs'
import DILNPTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DILNPTCRUD/DILNPTCRUD'

function Slider() {
    return (
        <DILNPTCRUD
            // common props
            api={SLIDERS_API}
            screenTopicSingular={'Slider'}
            screenTopicPlural={'Sliders'}


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            // allowDelete


            // field props
            maxPrecedence={3}

            textDBField={'link'}
            textName={'Link'}
            longTextDBField={'colorTitle'}
            longTextName={'Color Title'}

        />
    )
}

export default Slider
import { useState, useEffect } from "react"
import axios from "axios"
import { CONTACTS } from "../../../Utilities/APIs"


function ViewContact({

    //common props
    targetID, employee

}) {

    const [info, setInfos] = useState(null)

    useEffect(() => {


        async function fetchAndSetContactInfo() {

            const { data } = await axios.get(CONTACTS + 'getSingleContact/' + targetID)
            setInfos(data);
        }
        fetchAndSetContactInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {info &&
                <>
                    <h1>Phone</h1>
                    <p>{info.phone}</p>
                    <h1>Email</h1>
                    <p>{info.email}</p>
                    <h1>Facebook</h1>
                    <p>{info.facebook}</p>
                    <h1>Linkedin</h1>
                    <p>{info.linkedin}</p>
                    <h1>Youtube</h1>
                    <p>{info.youtube}</p>

                </>
            }
        </div>
    )
}

export default ViewContact
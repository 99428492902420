import axios from "axios"
import { useEffect, useState } from "react"
// import { RiDeleteBin2Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { EMPLOYEES_API } from "../../../../Utilities/APIs"
// import Modal from "../../../Partials/Elements/Modal/Modal"
// import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import {  RiDeleteBin2Line, RiVipCrown2Line } from "react-icons/ri"
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import RemoveEmployee from "./RemoveEmployee"
import useLevels from "../../../../Utilities/Permissions/useLevels"
import CreateAdmin from "./CreateAdmin"

function Employees() {

    const [employees, setEmployees] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [toggleFetch, setToggleFetch] = useState(false)
    const [showRemoveEmployee, setShowRemoveEmployee] = useState(false)
    const [showCreateAdmin, setShowCreateAdmin] = useState(false)

    const { employee } = useSelector(state => state.auth)

    const { superAdmin } = useLevels()

    useEffect(() => {

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetEmployees() {
            const { data } = await axios.get(EMPLOYEES_API + 'getAllEmployees', config)
            setEmployees(data)
        }

        fetchAndSetEmployees()

    }, [employee.token, toggleFetch])

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    function handleClose() {
        setShowModal(false)
        setShowRemoveEmployee(false)
        setShowCreateAdmin(false)
    }

    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Employees (${employees?.length} in total)`}</h1>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Image' />
                        <CRUDth th='Name' />
                        <CRUDth th='Email Address' />
                        <CRUDth th='Level' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {employees?.map(e => (
                            <CRUDTableRow key={e._id}>
                                <ImageCell
                                    imgSrc={e.dp}
                                    imgAlt={e.name}
                                />
                                <ShortTextCell text={e.name} />
                                <ShortTextCell text={e.email} />
                                <ShortTextCell text={e.level} />

                                {
                                    superAdmin && 
                                    <td className="action_button_cell">
                                        {!(e.level === 'admin' || e.level === 'superAdmin') &&
                                            <CRUDButton

                                                handleClick={() => {
                                                    setShowModal(true)
                                                    setShowCreateAdmin(true)
                                                    setTargetID(e._id)

                                                }}
                                            >
                                                <RiVipCrown2Line />
                                            </CRUDButton>
                                        }

                                        {!(e.level === 'superAdmin') &&

                                            <CRUDButton

                                                handleClick={() => {
                                                    setShowModal(true)
                                                    setShowRemoveEmployee(true)
                                                    setTargetID(e._id)

                                                }}
                                            >
                                                <RiDeleteBin2Line />
                                            </CRUDButton>
                                        }
                                    </td>
                                }

                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal && showCreateAdmin &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (`Create Admin`)
                    }
                >
                    {showCreateAdmin &&
                        <CreateAdmin
                            targetID={targetID}
                            setShowModal={setShowModal}
                            setShowCreateAdmin={setShowCreateAdmin}
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showRemoveEmployee &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (`Remove Employee`)
                    }
                >
                    {showRemoveEmployee &&
                        <RemoveEmployee
                            targetID={targetID}
                            setShowModal={setShowModal}
                            setShowRemoveEmployee={setShowRemoveEmployee}
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }
        </>
    )
}

export default Employees
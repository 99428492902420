import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiGroupLine, RiUserShared2Line } from 'react-icons/ri'
import useLevels from '../../../Utilities/Permissions/useLevels'

function UsersScreen() {
    const {superAdmin} = useLevels()
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Employees'} navCardLink='/employees' ><RiGroupLine /></NavCard>
            <NavCard cardName={'Employee Invites'} navCardLink='/employeeInvites' ><RiUserShared2Line /></NavCard>
            {
              superAdmin &&
            <NavCard cardName={'Admins'} navCardLink='/admins' ><RiGroupLine /></NavCard>
            }

        </NavCardList>
    )
}

export default UsersScreen
import { useState } from "react"
import axios from "axios"
import slugify from 'react-slugify';

import { BLOGS_API } from "../../../Utilities/APIs"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Modal from "../../Partials/Elements/Modal/Modal"
import MediaGallery from "../../Partials/Layouts/MediaGallery/MediaGallery"

import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor"
import { useEffect } from "react"
import { RiVideoAddLine } from "react-icons/ri";
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";



function UpdateBlog({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [showSecondModal, setShowSecondModal] = useState(false);
    const [showMediaGallery, setShowMediaGallery] = useState(false);
    const [activeTab, setActiveTab] = useState(1);

    const [name, setName] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [shortDescription, setShortDescription] = useState('')
    const [precedence, setPrecedence] = useState('')

    const [errorSmg, setErrorSmg] = useState('');

    useEffect(() => {

        async function fetchAndSetBlogInfo() {

            const { data } = await axios.get(BLOGS_API + 'getSingleBlogById/' + targetID)
            setName(data.name);
            setImage(data.image);
            setDescription(data.description);
            setShortDescription(data.shortDescription);
            setPrecedence(data.precedence);
        }
        fetchAndSetBlogInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('image', image)
        itemData.append('description', description)
        itemData.append('shortDescription', shortDescription)
        itemData.append('precedence', precedence)

        try {
            const response = await axios.patch(BLOGS_API + targetID, itemData)
            if (response.status === 200) {

                setShowUpdateForm(false);
                setShowModal(false);

                triggerFetch()
            }
        }

        catch (error) {
            setErrorSmg(error.response.data.message)
        }
    }

    function handleClose() {
        setShowSecondModal(false)
        setShowMediaGallery(false)
    }

    return (
        <>
            <div className="navtab_action">
                <div className="nav_tab">
                    <button
                        type="button"
                        className={activeTab === 1 ? 'active' : ''}
                        onClick={() => setActiveTab(1)}
                    >
                        <span>details</span>
                    </button>
                    <button
                        type="button"
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => setActiveTab(2)}
                    >
                        <span>write up</span>
                    </button>
                </div>
                <button
                    className="btn_snmd_open"
                    onClick={() => {
                        setShowSecondModal(true)
                        setShowMediaGallery(true)
                    }}
                >
                    <RiVideoAddLine />
                    <span>Media Gallery</span>
                </button>
            </div>
            <Form onSubmit={handleSubmit} hasImage>
                <div className={`nav_content ${activeTab === 1 ? 'active' : ''}`}>
                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />

                    <ShortTextInput
                        label={`Slug`}
                        value={slugify(name)}
                        placeholder={`Enter Slug`}
                        disabled
                    />

                    <ImageInput
                        fieldId='2'
                        state={image}
                        setState={setImage}
                        allowUpdateImage
                    >
                        Upload Card Image
                    </ImageInput>

                    <ShortTextInput
                        label={`Short Description`}
                        value={shortDescription}
                        placeholder={`Enter Short Description`}
                        setState={setShortDescription}
                    />

                    <NumberInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Enter Precedence`}
                        setState={setPrecedence}
                    />
                </div>
                <div className={`nav_content ${activeTab === 2 ? 'active' : ''}`}>
                    <CustomEditor
                        setState={setDescription}
                        data={description}
                    />
                </div>

                {(errorSmg !== "") &&
                    <span className="error_smg">{errorSmg}</span>
                }

                <FormSubmitButton text='Update Blog' />
            </Form>

            {showSecondModal &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        `Media gallery`
                    }
                >

                    {showMediaGallery &&
                        <MediaGallery
                            initialScreen='blog'
                        />
                    }
                </Modal>
            }
        </>
    )
}

export default UpdateBlog
import { createContext, useEffect, useState } from "react";
import { QUOTES } from "../Utilities/APIs";
import axios from "axios";

const AppContext = createContext({})


function AppContextProvider({ children }) {

    const [pendingRequests, setPendingRequests] = useState(null)
    const [toggleFetch, setToggleFetch] = useState(false)

    function triggerFetchRequest() {
        setToggleFetch(prev => !prev)
    }
    useEffect(() => {

        async function fetchAndSetQoutes() {
            const { data } = await axios.get(QUOTES + 'totalPendingQuotes')
            setPendingRequests(data)
        }
        fetchAndSetQoutes()


    }, [toggleFetch])


    return (
        <AppContext.Provider
            value={{
                pendingRequests,
                triggerFetchRequest
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export {
    AppContextProvider
}

export default AppContext;
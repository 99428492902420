import { useContext } from 'react'
import { RiHonourLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import AppContext from '../../../../../Context/AppContext'
import './RequestSideBarItem.css'

function RequestSideBarItem({ link, children, dataTip, dataFor }) {
    const { pendingRequests } = useContext(AppContext)

    return (
        <>
            <div className='small_sidebar_item' data-tip={dataTip} data-for={dataFor} >
                <NavLink to={link ? link : '/'}>
                    <button type="button" >
                        {
                            pendingRequests > 0
                                ?
                                <div className='pending'>
                                    <h3>
                                        {pendingRequests}
                                    </h3>
                                </div>
                                :
                                <RiHonourLine />
                        }
                    </button>
                </NavLink>
            </div>
            <ReactTooltip id={dataFor} place='right' effect='solid' />
        </>
    )
}

export default RequestSideBarItem
import { useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { CONTACTS } from "../../../Utilities/APIs"
import EmailInput from "../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"
import LinkInput from "../../Partials/Layouts/Forms/FormInputs/LinkInput/LinkInput"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import { useEffect } from "react"

function UpdateContact({

    employee, setShowUpdateForm, setShowModal, triggerFetch, targetID

}) {

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [facebook, setFacebook] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [youtube, setYoutube] = useState('')

    useEffect(() => {


        async function fetchAndSetInfo() {

            const { data } = await axios.get(CONTACTS + 'getSingleContact/' + targetID)
            setPhone(data.phone);
            setEmail(data.email);
            setFacebook(data.facebook);
            setLinkedin(data.linkedin);
            setYoutube(data.youtube);
        }
        fetchAndSetInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()
       const contactInfos = { phone,  email, facebook , linkedin ,youtube}
   
        const response = await axios.patch(CONTACTS + targetID , contactInfos )

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
             <NumberInput
                label={`Phone`}
                value={phone}
                placeholder={`Enter Phone`}
                setState={setPhone}
            />
            <EmailInput
                label={`Email`}
                value={email}
                placeholder={`Enter Email`}
                setState={setEmail}
            />
            <LinkInput
                label={`Facebook`}
                value={facebook}
                placeholder={`Enter Facebook`}
                setState={setFacebook}
            />
            <LinkInput
                label={`Linkedin`}
                value={linkedin}
                placeholder={`Enter Linkedin`}
                setState={setLinkedin}
            />
            <LinkInput
                label={`Youtube`}
                value={youtube}
                placeholder={`Enter Youtube`}
                setState={setYoutube}
            />

            <FormSubmitButton text='Update Contact' />
        </Form>
    )
}

export default UpdateContact
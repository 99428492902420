import { useState, useEffect } from "react"
import axios from "axios"
import {  QUOTES } from "../../../Utilities/APIs"


function ViewQoute({

    //common props
    targetID, employee

}) {

    const [info, setInfos] = useState(null)

    useEffect(() => {


        async function fetchAndSetQouteInfo() {

            const { data } = await axios.get(QUOTES  + 'getSingleQuotes/' + targetID)
            setInfos(data);
        }
        fetchAndSetQouteInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {info &&
                <>
                    <h1>Name</h1>
                    <p>{info.name}</p>
                    <h1>Phone</h1>
                    <p>{info.phone}</p>
                    <h1>Email</h1>
                    <p>{info.email}</p>
                    <h1>Organization</h1>
                    <p>{info.organization}</p>
                    <h1>Message</h1>
                    <p>{info.message}</p>
                    <h1>Status</h1>
                    <p>{info.status}</p>

                </>
            }
        </div>
    )
}

export default ViewQoute
import React from 'react'
import INTPCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/INTPCRUD/INTPCRUD'
import { TEAMS_API } from '../../../Utilities/APIs'

function Teams() {
    return (
        <INTPCRUD
            api={TEAMS_API}
            screenTopicSingular={'Name'}
            screenTopicPlural={'Teams'}


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            // allowDelete


            // field props
            maxPrecedence={10}
            textDBField={'designation'}
            textName={'Designation'}
        />
    )
}

export default Teams
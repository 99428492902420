import React from 'react'
import { DATA_TYPES } from '../../../../Utilities/APIs'
import DINPCRUD from '../../../Partials/Layouts/CRUDs/CRUDLayouts/DINPCRUD/DINPCRUD'

function DataTypes() {
  return (
    <DINPCRUD
            // common props
            api={DATA_TYPES}
            screenTopicSingular='Data Type'
            screenTopicPlural='Data Types'

            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            // allowDelete


            // field props
            maxPrecedence={6}

        />
  )
}

export default DataTypes
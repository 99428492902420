import { DATA_TYPES, SERVICES, SERVICE_DATA_TYPES } from "../../../../Utilities/APIs"
import PcSSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/PcSSCRUD"

function ServiceDataTypes() {
  return (
        <PcSSCRUD
            // common props
            api={SERVICE_DATA_TYPES}
            screenTopicSingular='Service Data Type'
            screenTopicPlural='Service Data Types'
            
            // crud header props
            showTotalInHeading
            
            // crud props
            allowCreate
            allowUpdate
            // allowDelete
            
            // select props
            hasSelect
            selectApi={SERVICES}
            selectType={'parent-child'}
            selectDBField={'service'}
            selectName={'Service'}

            // second select props
            hasSecondSelect
            secondSelectApi={DATA_TYPES}
            secondSelectType={'parent-child'}
            secondSelectDBField={'dataType'}
            secondSelectName={'Data Type'}
        />
  )
}

export default ServiceDataTypes
import React from 'react'
import { SOLUTION_USES } from '../../../Utilities/APIs'
import DINPCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINPCRUD/DINPCRUD'

function SolutionUses() {
    return (
        <DINPCRUD
            // common props
            api={SOLUTION_USES}
            screenTopicSingular='Solution Use'
            screenTopicPlural='Solution Uses'

            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            // allowDelete


            // field props
            maxPrecedence={5}

        />
    )
}

export default SolutionUses
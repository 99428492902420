import React from 'react'
import { RiDatabase2Fill, RiServiceLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function ServicesScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Services'} navCardLink='/services' ><RiServiceLine /></NavCard>
            <NavCard cardName={'Data Types'} navCardLink='/dataTypes' ><RiDatabase2Fill /></NavCard>

        </NavCardList>
    )
}

export default ServicesScreen
import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiChatQuoteLine, RiImage2Fill, RiToolsFill } from 'react-icons/ri'

function Homepage() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Sliders'} navCardLink='/sliders' ><RiImage2Fill /></NavCard>
            <NavCard cardName={'Testimonials'} navCardLink={'/testimonials'} ><RiChatQuoteLine /></NavCard>
            <NavCard cardName={'Tools'} navCardLink={'/tools'} ><RiToolsFill /></NavCard>

        </NavCardList>
    )
}

export default Homepage
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { IMAGE_URL, OPERATIONS, SERVICES } from "../../../../Utilities/APIs"
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
// import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"
import ViewOperation from "./ViewOperation"
import CreateOperation from "./CreateOperation"
import { useParams } from "react-router-dom"
import UpdateOperation from "./UpdateOperation"


function OperationsScreen() {
    const {parentID}=useParams()
    console.log(parentID);

    const [operations, setOperations] = useState(null)
    const [service, setService] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    

    const { employee } = useSelector(state => state.auth)

    useEffect(() => {

        async function fetchAndSetOperations() {
            const { data } = await axios.get(OPERATIONS + 'getOperationsFromService/' + parentID )
            setOperations(data)
        }
        fetchAndSetOperations()

        async function fetchAndSetService() {
            const { data } = await axios.get(SERVICES + 'getSingleService/' + parentID )
            setService(data)
        }
        fetchAndSetService()


    }, [toggleFetch,parentID])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Operations (${operations?.length} in total)`}</h1>
                    <CreateButton
                        screenTopicSingular='Operation'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                        
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Image' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {operations?.map(operation => (
                            <CRUDTableRow key={operation._id}>
                                <ShortTextCell text={operation.name} />
                                <ImageCell imgSrc={IMAGE_URL + operation.image} />

                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={operation._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={operation._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={operation._id}
                                        setTargetID={setTargetID}
                                    /> */}

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Operation`) ||
                        (showUpdateForm && `Update Operation`) ||
                        (showDeleteSection && `Delete Operation`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateOperation
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            service={service}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateOperation
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            service={service}
                        />
                    }
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={OPERATIONS}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Operation`)
                    }
                >
                    <ViewOperation
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default OperationsScreen
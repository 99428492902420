import React from 'react'
import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiDoubleQuotesR, RiMailLine } from 'react-icons/ri'

function QoutesRequestScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Pending Requests'} navCardLink={'/pendingRequests'} ><RiDoubleQuotesR /></NavCard>
            <NavCard cardName={'Accepted Requests'} navCardLink={'/allAcceptedRequests'} ><RiMailLine /></NavCard>
        </NavCardList>
    )
}

export default QoutesRequestScreen
import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import { RiDoubleQuotesR, RiProfileLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'

function RequestScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Quotation Requests'} navCardLink={'/qoutesRequestScreen'} ><RiDoubleQuotesR /></NavCard>
            <NavCard cardName={'Newsletter Subcriptions'} navCardLink='/subcriptions' ><RiProfileLine /></NavCard>
        </NavCardList>
    )
}

export default RequestScreen
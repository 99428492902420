import React from 'react'
// import { RiLayoutTop2Fill, RiProfileLine } from 'react-icons/ri'
// import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function CompanyPortfolioScreen() {
    return (
        <NavCardList numOfCards={'six'}>
            {/* <NavCard cardName={'Solution Uses'} navCardLink='/solutionUses' ><RiLayoutTop2Fill /></NavCard>
            <NavCard cardName={'Industry Uses'} navCardLink='/industryUses' ><RiProfileLine /></NavCard> */}

        </NavCardList>
    )
}

export default CompanyPortfolioScreen
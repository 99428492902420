// import DeleteClient from '../Clients/DeleteClient/DeleteClient';
// import { useState } from 'react';
// import Form from '../../Partials/Layouts/Forms/Form';
// import SelectInput from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput';
// import SelectOption from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOptions/SelectOption';
// import TextInput from '../../Partials/Layouts/Forms/FormInputs/TextInput/TextInput';

// import {  RiNumbersLine, RiPulseLine } from 'react-icons/ri';
// import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard';
import {  RiNumbersLine, RiPulseLine } from 'react-icons/ri';
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList';
import './Dashboard.css';
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard';
import { useContext } from 'react';
import AppContext from '../../../Context/AppContext';

function Dashboard() {
  const { pendingRequests } = useContext(AppContext)

  return (
    <section id='dashboard'>
      <NavCardList numOfCards={'four'}>
        <div className='nav_card'>
          <a href="https://www.hotjar.com/" target="_blank" rel="noreferrer" >
            <div>
              <RiPulseLine />
              <p>Hotjar</p>
            </div>
          </a>
        </div>

        <div className='nav_card'>
          <a href="https://analytics.google.com/" target="_blank" rel="noreferrer">
            <div>
              <RiNumbersLine />
              <p>Google Analytics</p>
            </div>
          </a>
        </div>
        <NavCard
          cardName={'Pending Requests'}
          navCardLink='/pendingRequests'
        >
         <h3 className='pending_request'> {pendingRequests}</h3>
        </NavCard>
      </NavCardList>

    </section>
  )
}

export default Dashboard